import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Button, Form, message, Radio, Space, Spin } from "antd";
import type { RadioChangeEvent } from 'antd';
import { useAuthContext } from "../common/providers/AuthProvider";
import { LoadingOutlined,  WalletOutlined } from "@ant-design/icons";
import "./payGateway.scss";

const PayGateway = ({
  directPayment = false,
  blockApiReq = () => { },
  loadingSpin,
  purchasetype,
  IsDeposit = false,
  Hold = false,
  OnPaymentModeSelection=()=>{}
}) => {
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const [PaymentOptionsVisible, setPaymentOptionsVisible] = useState(false);
  const showPaymentOptions = () => setPaymentOptionsVisible(true);
  const [IsHold, setHold] = useState(3);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
  );
  // This Variable is used get internally Path location
  const location = useLocation().pathname

  const isHold = (e: RadioChangeEvent) => {
    // console.log('radio checked', e.target.value);
    setHold(e.target.value);
  };

   
  useEffect(() => {
    if (user && !directPayment) {
      if (user.Role.RoleId === 5 || user.Role.RoleId === 2) {
        showPaymentOptions();
      }
    } else {
      // blockApiReq(1);
      showPaymentOptions();
    }
    if (purchasetype?.toUpperCase() == "BLOCK" && Hold) {//10 for hold and 3 for ticket
      setHold(10);
    }
  }, []);

  const submitform = (values) => {
    // if (values?.PgType != 1) {
    if (values?.HoldType == 10) {
      values.PgType = 10;
    }
    if (values?.PgType) blockApiReq(values?.PgType);
    // }else{
    // alert("Please contact admin for  easy wallet payment call @+919166868837");
    // };
  };

  return (
    <div>
      {PaymentOptionsVisible && (
        <Form layout="vertical" form={form} onFinish={submitform}>
          {purchasetype?.toUpperCase() == "BLOCK" && Hold && <Form.Item
            name="HoldType"
            label="Choose Type"
            rules={[{ required: true, message: "Select Type" }]}
          >


            <Radio.Group size="large" onChange={isHold} value={10}>
              <Space direction="vertical">
                {user.Role.RoleId === 5 && <Radio value={10}>Hold </Radio>}
                {user.Role.RoleId === 5 && <Radio value={3}>Ticket </Radio>}
              </Space>
            </Radio.Group>
          </Form.Item>}
          {IsHold == 3 && <Form.Item
            name="PgType"
            label={
              <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', backgroundColor: '#59dd50', padding: '5px', flexDirection: 'row' }}>
              <span style={{color:'#fff'}}>Choose Payment Type</span>
              <WalletOutlined style={{ marginLeft: '23px', color: '#fff' }} />
            </div>
            }
            // rules={[{ required: IsHold == 3 ? true : false, message: "Select Payment Gateway" }]}
            rules={[
              {
                required: false,
                validator: (_, value) =>
                  IsHold === 3 && !value ? Promise.reject(new Error("Select Payment Gateway")) : Promise.resolve(),
              },
            ]}
          >
            <Radio.Group size="large" onChange={OnPaymentModeSelection} >

              <Space direction="vertical">
                {user?.Role?.RoleId === 2 && <Radio value={3}>Wallet </Radio>}
                {user?.Role?.RoleId === 5 && IsDeposit && <Radio value={3}>Deposit</Radio>}

                {/* Add Code For Deposite Button On MemberShip Id  --Changes By Kathik --21/April/2022  Code Updated --06/April/2023*/}
                {/* {(() => {
                  if(location == "/activities/checkout"){
                    return user?.Role?.RoleId === 5 && IsDeposit?  <Radio value={3}>Deposit</Radio> : null
                  }else{
                    return  user?.Role?.RoleId === 5 ? <Radio value={3}>Deposit</Radio>:null /// update on 14-05-2023
                  }
                })()} */}
                {/* Code End By Kathik */}
                <Radio value={1}>Payment Gateway</Radio>
              </Space>
            </Radio.Group>

          </Form.Item>}
          {loadingSpin ? (
            <Button className="payment-btn" disabled>
              <Spin indicator={antIcon} />
            </Button>
          ) : (
            <Button className="payment-btn" onClick={() => form.submit()}>
              Continue
            </Button>
          )}
        </Form>
      )}
    </div>
  );
};

export default PayGateway;
