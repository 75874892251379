import React, { useState } from "react";
import { Layout, Form, Input, Select, Button, Row, Col, Image, List, Typography, message } from 'antd';
import './landingPage.scss'
import Icon, { CheckCircleFilled, ClockCircleFilled, AlipayCircleOutlined, UsergroupAddOutlined, AppstoreAddOutlined } from "@ant-design/icons";
import ApiClient from "../../helpers/ApiClient";

const BaliLandingPage = () => {

    const [form] = Form.useForm(); // Ant Design form instance
    const [responseMessage, setResponseMessage] = useState(""); // To display API response
    const [errorMessage, setErrorMessage] = useState(""); // To handle errors

    const handleSubmit = async (values) => {
        const { Name, LastName, Phone, Email } = values;
        if (!Name || !Phone || !Email) {
            message.error('Required fields are missing');
            return;
        }
        ApiClient.post('StoreVisitors/visitorDetails', {
            Name,
            LastName,
            Phone,
            Email,
        }).then((res) => {
            if (res.status === 201) {
                message.success('User details saved successfully');
            } else {
                message.info('Something Went Wrong');
            }
        }).catch((error) => {
            console.log(error.message)
        })
    };

    return (
        <div className="landing-page">
            <section className="hero-section"
                style={{
                    background: "url('https://sastadmc.com/bali-indian.png') no-repeat center center",
                    backgroundSize: "cover",
                    color: "#fff",
                    padding: "50px 20px", 
                    textAlign: "center"
                }}>
                <div className="container">
                    <div className="blog-wrapper"
                        style={{
                            padding: "10px",
                            textAlign: "start", 
                            margin: "auto", 
                            backgroundColor: " rgba(0, 0, 0, 0.58)",
                            borderRadius: "8px",
                            width: "90%", 
                            maxWidth: "460px", 
                        }}
                    >
                        <Typography.Title
                            level={1}
                            style={{ fontWeight: '900', color: 'white', textAlign:'center' }}
                        >
                            Explore Best of Bali
                        </Typography.Title>
                        <Typography.Paragraph style={{ color: 'white', textAlign:"center", fontSize:"large" }}>
                            <b>Get Best Flights, Tour Itinerary and Customized Packages for Bali</b>
                        </Typography.Paragraph>
                    </div>

                    <div className="booking-form"
                        style={{
                            backgroundColor: "#0b3448",
                            padding: "30px",
                            borderRadius: "10px",
                            marginTop: "20px",
                            height: "auto", 
                            width: "90%", 
                            maxWidth: "500px", 
                            marginLeft: "auto",
                            marginRight: "auto" 
                        }}>
                        <span style={{ fontSize: 'x-large', fontWeight: 'bolder' }}>Book Your Tour</span>
                        <Form form={form} layout="vertical" style={{ marginTop: '15px' }} onFinish={handleSubmit}>
                            <Row gutter={[16, 16]}>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Full Name"
                                        name="Name"
                                        rules={[{ required: true, message: 'Please enter your full name!' }]}
                                    >
                                        <Input placeholder="Enter Full Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Last Name"
                                        name="LastName"
                                        rules={[{ required: true, message: 'Please enter your last name!' }]}
                                    >
                                        <Input placeholder="Enter Last Name" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Email Address"
                                        name="Email"
                                        rules={[
                                            { required: true, message: 'Please enter your email address!' },
                                            { type: 'email', message: 'Please enter a valid email address!' },
                                        ]}
                                    >
                                        <Input type="email" placeholder="Enter Email Address" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12}>
                                    <Form.Item
                                        label="Phone Number"
                                        name="Phone"
                                        rules={[{ required: true, message: 'Please enter your phone number!' }]}
                                    >
                                        <Input placeholder="Enter Phone Number" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24}>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit" block>
                                            Submit Enquiry
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        {responseMessage && <div style={{ color: 'green' }}>{responseMessage}</div>}
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                    </div>
                </div>
            </section >

            <section className="container my-5">
                <div className="row">
                    {/* Left Column: Image */}
                    <div className="col-md-6">
                        <img
                            alt="Tanah Lot Temple "
                            className="img-fluid rounded"
                            src="https://sastadmc.com/tanha.jpg"
                            style={{height:"460px"}}
                        />
                    </div>

                    {/* Right Column: Content */}
                    <div className="col-md-6">
                        <h2>
                            Shop Your Trip can satisfy all your travel needs.
                        </h2>
                        <p style={{lineHeight:"1.5"}}>
                            We can book flights, hotels, tour activities, and tailor-made packages at affordable prices. So, say goodbye to hassle and hello to memorable travel experiences in your budget.
                        </p>

                        {/* List Section */}
                        <ul className="list-unstyled"
                            style={{
                                padding: "0",
                                listStyle: "none",
                                lineHeight: "2.2rem"
                            }}>
                            <li
                             style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Provided Payment Security
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Safe Journey
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Experienced Guide
                            </li>
                            <li
                            style={{
                                display: "flex",
                                    alignItems: "center",
                                    marginBottom: "5px",
                                    fontSize: "1rem"
                             }}>
                                <CheckCircleFilled style={{ color: 'green' }} />
                                Best Sightseeing
                            </li>
                        </ul>

                        {/* Enquiry Button */}
                        <div>
                            <a className="btn btn-danger" href="#">
                                Enquiry Now
                            </a>
                        </div>

                        {/* Stats Section */}
                        <div className="d-flex justify-content-between">
                            <div>
                                <h3>75K+</h3>
                                <p>Happy Clients</p>
                            </div>
                            <div>
                                <h3>5000+</h3>
                                <p>Memorable Tours</p>
                            </div>
                            <div>
                                <h3>95%</h3>
                                <p>Client Satisfaction</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-black py-5">
                <div className="container-fluid" style={{ maxWidth: '1320px' }}>
                    <div className="section-title">
                        <h2>Bali Tour Packages</h2>
                        <p>
                            Skyscrapers, Shopping Sites, Culture And More, Checkout
                            Our Bali Tour Packages For Happiness Galore.
                        </p>
                    </div>
                    <div className="row">
                        {/* Package 1 */}
                        <div className="col-md-4">
                            <div className="card">
                                <img
                                    alt="Night view of Abu Dhabi cityscape"
                                    className="card-img-top"
                                    src="https://sastadmc.com/bali-swing.jpg"
                                />
                                <div className="card-body">
                                    <h5>03 Nights 04 Days</h5>
                                    <ul>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            03 Night Stay In Bali.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily Breakfast.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Sightseeing.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Bali Swing. 
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Nusa Penida Full-day Tour.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Tanah Lot Temple.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Tirta Tangga.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Bananaboat.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Visa on arrival.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tour & Transfer Private Basis.
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Package 2 */}
                        <div className="col-md-4">
                            <div className="card">
                                <img
                                    alt="Night view of Bali cityscape"
                                    className="card-img-top"
                                    src="https://sastadmc.com/bali-uluwatu.jpeg"
                                />
                                <div className="card-body">
                                    <h5>04 Nights  and 05 days </h5>
                                    <ul>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            02 Night Stay In Ubud.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            02 Night Stay In Kuta.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily Breakfast.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Sightseeing.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Water Sports (Jet Ski , Parasailing ).
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Kecak Dance & Barong Dance.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Uluwatu Temple & Monkey Forest.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Handara Gate & Bali Swing.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Visa On Arrival.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tour & Transfer Private Basis.
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* Package 3 */}
                        <div className="col-md-4">
                            <div className="card">
                                <img
                                    alt="Night view of Bali cityscape"
                                    className="card-img-top"
                                    src="https://sastadmc.com/beach-bali.jpg"
                                    style={{height:"228px"}}
                                />  
                                <div className="card-body">
                                    <h5>06 Nights and 07 days </h5>
                                    <ul>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            02-night stay in Ubud.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            02-night stay in Kuta.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            02-night stay in Nusa Penida.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Daily Breakfast.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Snorkeling & Tegalalang rice terrace.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Atv ride & Bali swing.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Nusa Penida & Pandawa beach.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Pickup & Drop Private Basis & Interhotel Transfer on Private Basis.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            Visa On Arrival.
                                        </li>
                                        <li>
                                            <CheckCircleFilled style={{ color: 'green' }} />
                                            All Tour & Transfer Private Basis.
                                        </li>
                                    </ul>
                                    <a className="btn btn-danger" href="#">
                                        Send Enquiry
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="testimonial">
                <div className="container">
                    <div className="section-title">
                        <h2>What Our Customers Said About Us</h2>
                    </div>
                    <div className="row">
                        {/* Video Testimonials */}
                        {[
                            "https://www.youtube.com/embed/DnbCWefjg68",
                            "https://www.youtube.com/embed/I5-JmUsWxhM",
                            "https://youtube.com/embed/UyWhm93CCU0",
                            "https://www.youtube.com/embed/YHZvFpV7Jp0",
                            "https://youtube.com/embed/dgQU-nNTldg?feature=share",
                            "https://youtube.com/embed/IfvDuBDqZ0Q?feature=share",
                        ].map((src, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="card">
                                    <div className="d-flex align-items-center">
                                        <iframe
                                            src={src}
                                            frameBorder="0"
                                            allow="autoplay; encrypted-media"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {/* Image Testimonials */}
                        {[
                            "https://sastadmc.com/seven.jpg",
                            "https://sastadmc.com/five.jpg",
                            "https://sastadmc.com/two.jpg",
                        ].map((src, index) => (
                            <div className="col-lg-4 col-md-6" key={index}>
                                <div className="card">
                                    <img
                                        src={src}
                                        alt={`Testimonial image ${index + 1}`}
                                        className="card-img-top"
                                        style={index === 1 || index === 2 ? { height: '240px' } : {}}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            <section class="container my-5">
                <div class="section-title">
                    <h2 style={{
                        fontSize: "2.5rem",
                        fontWeight: "600",
                        textAlign: "center",
                        marginBottom: "15px",
                        color: "#000"
                    }}>
                        Popular Activities Open For Visitors
                    </h2>
                </div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Bali Mall" class="card-img-top" height="300" src="https://sastadmc.com/atv-bali.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                    Bali ATV Rides
                                </h5>
                                <p class="card-text">
                                Explore the Landscape,Villages
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Bali Fountain" class="card-img-top" height="300" src="https://sastadmc.com/bali-cycling.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Cycling in Bali
                                </h5>
                                <p class="card-text">
                                Mountain areas and Rice terraces
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Mall of Emirates" class="card-img-top" height="300" src="https://sastadmc.com/boat-ride.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Boat Riding in Bali
                                </h5>
                                <p class="card-text">
                                Watching a sunset, Fishing
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="The Burj Khalifa" class="card-img-top" height="300" src="https://sastadmc.com/scuba-bali.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Scuba Diving in Bali
                                </h5>
                                <p class="card-text">
                                Exploring  colorful fish, coral reefs
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Bali Marina" class="card-img-top" height="300" src="https://sastadmc.com/parasailing-bali.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Parasailing in Bali
                                </h5>
                                <p class="card-text">
                                Enjoy the views and the warm water
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img alt="Palm Jumeirah" class="card-img-top" height="300" src="https://sastadmc.com/mount-batur.jpg" width="400" />
                            <div class="card-body">
                                <h5 class="card-title">
                                Trekking in Bali
                                </h5>
                                <p class="card-text">
                                Explore the Hills and Lake
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-dark text-white py-5">
                <div className="container">
                    <div className="section-title">
                        <h2 style={{textAlign:"center"}}>Why Shop Your Trip?</h2>
                        <p
                            style={{
                                fontSize: "1rem",
                                lineHeight: "1.6",
                                maxWidth: "72ch",
                                textAlign: "justify",
                                margin: "0 auto 30px",
                                color: "#fff",
                            }}>
                            Shop Your Trip can satisfy all your travel needs. Here, you can book flight tickets, hotels, bus tickets, activities, and holiday packages at a cost-effective price. So, why go anywhere else? Visit us for a memorable travel experience in your budget.
                        </p>
                    </div>
                    <div className="row">
                        {/* Left Image */}
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <img
                                src="https://sastadmc.com/sytteam.jpg"
                                alt="Bali City"
                                className="img-fluid rounded"
                            />
                        </div>

                        {/* Right Features List */}
                        <div className="col-lg-6">
                            <ul className="list-unstyled" style={{lineHeight:"0.9"}}>
                                <li>
                                    <ClockCircleFilled style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Time</h5>
                                        <p>We invest in multiple sites to compare prices for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AlipayCircleOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Save Money</h5>
                                        <p>Compare multiple sites to find the best deal for you.</p>
                                    </div>
                                </li>
                                <li>
                                    <AppstoreAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Multiple Options</h5>
                                        <p>Multiple itineraries & personalized suggestions based on interest.</p>
                                    </div>
                                </li>
                                <li>
                                    <UsergroupAddOutlined style={{ fontSize: "2rem", color: "antiquewhite" }} />
                                    <div>
                                        <h5>Trusted Network</h5>
                                        <p>Reliable, flexible & verified travel guides & exclusive information.</p>
                                    </div>
                                </li>
                            </ul>
                            <div className="text-center mt-4">
                                <a href="#" className="btn btn-danger">
                                    Send Enquiry
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}
export default BaliLandingPage