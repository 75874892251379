import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import { Col, Row, Layout, Rate, DatePicker, Form, Button } from "antd";
import moment from "moment";
import "../FixedDepartures/FixedDepartures.scss";
import queryString from "query-string";
import ApiClient from "../../helpers/ApiClient";
import { useCurrencyContext } from "../../common/providers/CurrencyProvider";
import { useSytContext } from "../../common/providers/SytProvider";
import Banner from "../../components/banner/Banner";
import ActivitiesAutoComplete from "../../common/AutoCompleteSelect/ActivitiesAutoComplete";
import { useAuthContext } from "../../common/providers/AuthProvider";
const FixedDepartures = (props) => {
  useCurrencyContext();
  const { activityBanners } = useSytContext();
  const { Content } = Layout;
  const [form] = Form.useForm();
const {
    isLogin: { agent },
  } = useAuthContext();
  const city = useRef(null);
  const dateBox = useRef(null);

  const oriDateString = "YYYY-MM-DD";
  const dateFormat = "YYYY-MM-DD";

  const [cityActivitiesData, setCityActivitiesData] = useState([]);
  let history = useHistory();

  const onSubmitForm = (val) => {
    let countryCities = val.activitiesCityCode;

    let query = {
      country: countryCities.split("&&")[0],
      city: countryCities.split("&&")[1],
      travelDate: moment(val.journeyDate).format(oriDateString),
    };

    let queryParams = queryString.stringify(query);

    if (props.modify) {
      history.replace("/activities/results?" + queryParams);
      props.onModify();
    } else {
      history.push("/activities/results?" + queryParams);
    }
  };

  const disabledOriginDate = (currentDate) => {
    return currentDate < moment().startOf("day");
  };

  useEffect(() => {
    if (props.modify) {
      const activitiesSearchParams = queryString.parse(window.location.search);

      form.setFieldsValue({
        activitiesCityCode: `${activitiesSearchParams.country}&&${activitiesSearchParams.city}`,
        journeyDate: moment(activitiesSearchParams.travelDate),
      });
    }
  }, []);

  const handleOnSubmit = (ref) => {
    ref.current.focus();
  };

  const getCityActivitiesList = () => {
    ApiClient.get("extranet/getAllActivities")
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.filter(
            (item) =>
              item.showOnHomePage === 1 &&
              item.continent === 3 &&
              item.status === 1
          );
          setCityActivitiesData(data);
        } else {
          setCityActivitiesData([]);
        }
      })
      .catch(() => {
        setCityActivitiesData([]);
      });
  };

  useEffect(() => {
    getCityActivitiesList();
  }, []);

  return (
    <div className="">
      <div className="bus-block">
        <div className="bus-banner">
          <Banner banner={activityBanners} />
        </div>
        <div className="bus-search-header">
          <h2>Activities & Things To Do Around the World</h2>
        </div>
        <div className="buses-search">
          <div className="hotel_search acitiv-pans">
            <Form form={form} onFinish={onSubmitForm}>
              <Row className="search-row activity-cols-num" gutter={16}>
                <Col
                  md={14}
                  xs={24}
                  className="from-to-inputs new-activity-pro br-right"
                >
                  <ActivitiesAutoComplete
                    formItemProps={{
                      label: "City",
                      name: "activitiesCityCode",
                      rules: [
                        { required: true, message: "City Name is required" },
                      ],
                    }}
                    selectProps={{
                      size: "large",
                      placeholder: "Search by Destination",
                    }}
                    api={"activities/sastaEuropeCityAutosuggestion/"}
                    refName={city}
                    focusRef={dateBox}
                    handleOnSubmit={handleOnSubmit}
                    modifySearch={props.modify ? props.modify : false}
                  />
                </Col>
                <Col md={6} xs={24} className="from-to-inputs new-activity-pro">
                  <Form.Item
                    name="journeyDate"
                    rules={[{ required: true }]}
                    initialValue={moment()}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      allowClear={false}
                      ref={dateBox}
                      size="large"
                      className="search-inputs journeyDate"
                      placeholder="Journey Date"
                      format={dateFormat}
                      disabledDate={disabledOriginDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={24} className="new-activity-pro">
                  <Form.Item className="mr-none">
                    <Button
                      size="large"
                      className="primary-btn train-search-btn button-promo-activity"
                      htmlType="submit"
                    >
                      <SearchOutlined />
                      Search
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>

      <section className="bus-support-24">
        <div className="container">
          <Row>
            <Col md={12} xs={24}>
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>BEST PRICE GUARANTEED</p>
                </div>
              </div>
            </Col>
            <Col md={12} xs={24} className="support-24">
              <div className="offers-bal-cart">
                <img
                  className="deals-coloums-24"
                  src={require("../../assets/images/one-icon-ts1.png").default}
                  alt=""
                />
                &nbsp;
                <div className="price-deals-24">
                  <p>24×7 SUPPORT</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="help-support-cc">
        <div className="container">
          <Row className="trip-help-row">
            <Col md={24} xs={24}>
              <h3 className="trip-help-cc text-danger">Shop Your Trip Fixed Departures...</h3>
            </Col>

            <Col md={12} xs={24} className="activity-panel-img-cc">
              {/* Image Section */}
              <img
                src={require("../../assets/Agents/FD3.png").default}
                alt="09 Nights and 10 Days"
                className="img-fluid" // Ensures the image scales with its container
              />

              {/* Title Link Section */}
              <p className="title-link">
                <a
                  href={agent
                    ? require("../../assets/Agents/FD1.png").default
                    : require("../../assets/Users/FD1.png").default}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  09 Nights and 10 Days
                </a>
              </p>

              {/* PDF Links */}
              <div className="pdf-link">
                <p style={{marginTop:"30px"}}>
                  <a
                    href={agent
                      ? require("../../assets/Agents/FD2.pdf").default
                      : require("../../assets/Users/FD2.pdf").default}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View More for Flight Details
                  </a>
                </p>
                <p>
                  <a
                     href={agent
                      ? require("../../assets/Agents/FD1.pdf").default
                      : require("../../assets/Users/FD1.pdf").default}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View More Fixed Departures Details
                  </a>
                </p>
              </div>
            </Col>

            <Col md={12} xs={24} className="activity-panel-img-cc">
              {/* Image Section */}
              <img
                src={require("../../assets/Agents/FD4.png").default}
                alt="12 Nights and 13 Days"
                className="img-fluid" // Ensures the image scales with its container
              />

              {/* Title Link Section */}
              <p className="title-link">
                <a
                  href={agent
                    ? require("../../assets/Agents/FD2.png").default
                    : require("../../assets/Users/FD2.png").default}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  12 Nights and 13 Days
                </a>
              </p>

              {/* PDF Links */}
              <div className="pdf-link">
                <p style={{marginTop:"30px"}}> 
                  <a
                    href={agent
                      ? require("../../assets/Agents/FD4.pdf").default
                      : require("../../assets/Users/FD4.pdf").default}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View More for Flight Details
                  </a>
                </p>
                <p>
                  <a
                    href={agent
                      ? require("../../assets/Agents/FD3.pdf").default
                      : require("../../assets/Users/FD3.pdf").default}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    View More Fixed Departures Details
                  </a>
                </p>
              </div>
            </Col>
          </Row>

        </div>
      </section>
    </div>
  );
};

export default FixedDepartures;
