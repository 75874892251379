import React from "react";

import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { useSytContext } from "../providers/SytProvider";
import { AppConstants } from "../../helpers/constants";
import {
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import "../footer/Footer.scss";

const Footer = () => {
  const { siteDetails } = useSytContext();

  return (
    <div id="footer">
      <div className="footer_links_wrapper e-hide">
        <div className="links_wrapper">
          <Row>
            <Col md={24}>
              <div className="social_links">
                <a href="#!">
                  <i className="fa fa-facebook"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-google"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-twitter"></i>
                </a>
                <a href="#!">
                  <i className="fa fa-instagram"></i>
                </a>
              </div>
            </Col>
            <Col className="d-none" md={8}>
              <div className="social_fb_text">
                <Button variant="primary" size="sm">
                  <i className="fa fa-thumbs-up" aria-hidden="true"></i>
                  Like
                </Button>
                <Button variant="primary" size="sm">
                  Share
                </Button>
                <small>
                  721k peope like this.<a href="#!">Sign Up</a>to see what your
                  friends like.
                </small>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="footer_bottom_section bottom-postion-ft">
        <div className="bg-ft-col-22">
          <Row className="add-logos-ft">
            <Col md={12} xs={24} className="ft-col-1">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1-tr">
                    Our<span> Happy Travellers</span>
                  </h3>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>25000+</h3>
                  <p>Happy Travellers</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>5000+</h3>
                  <p>Memorable Tours</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100+</h3>
                  <p>Awards</p>
                </Col>
                <Col md={6} xs={12} className="traveller-family-c2 tr-right-c3">
                  <h3>100%</h3>
                  <p>Reliable</p>
                </Col>
              </Row>
            </Col>
            <Col md={12} xs={24} className="ft-col-2">
              <Row>
                <Col md={24} xs={24}>
                  <h3 className="our-affi-c1">
                    Our <span>Affiliations</span>
                  </h3>
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-7.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-8.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-9.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-10.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-11.png").default}
                    alt=""
                  />
                </Col>
                <Col md={4} xs={8}>
                  <img
                    className="trv-img-cc-3"
                    src={require("../../assets/images/ft-cc-12.png").default}
                    alt=""
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer_social_info e-hide">
          <div className="antd-container">
            <ul>
              <li>
                <i className="fa fa-birthday-cake cake-icon"></i>
                <p>
                  <strong> 15 years</strong>
                  <br />
                  of experience
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>50</strong>
                  <br />
                  countries
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong> 150 M</strong>
                  <br />
                  customers
                </p>
              </li>
              <li>
                <i className="fa fa-globe"></i>
                <p>
                  <strong>950</strong>
                  <br />
                  airlines
                </p>
              </li>
              <li>
                <i className="hotels-icon">H</i>
                <p>
                  <strong>1.3 M</strong>
                  <br />
                  hotels
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="bottom_countries countries-top">
          <div className="antd-container">
            <div className="countries-top-section">
              <div className="countries_links">
                <div className="footr-inter-sites">
                  <Row>
                    <Col md={4} xs={24}>
                      <p>OUR PRODUCTS</p>
                      <ul className="cms-pages-links">
                        {/* <li>
                          <Link to="/">Book Flights</Link>
                        </li> */}
                        <li>
                          <a href="/">Book Flights</a>
                        </li>

                        <li>
                          <Link to="/web-check-in">Flight Status </Link>
                        </li>

                        <li>
                          <Link to="/hotels">Hotel Booking </Link>
                        </li>

                        {/* <li>
                          <Link to="/">Domestic Flights </Link>
                        </li> */}
                        <li>
                          <a href="/">Domestic Flights</a>
                        </li>

                        <li>
                          <a href="/">International Flights </a>
                        </li>

                        <li>
                          <Link to="/bus">Bus</Link>
                        </li>

                        <li>
                          <Link to="/visa">Visa </Link>
                        </li>
                        <li>
                          <Link to="/insurance">Insurance </Link>
                        </li>
                        <li>
                          <Link to="/activities">Activities</Link>
                        </li>

                        <li>
                          <Link to="/buildyourpackage">Build Package </Link>
                        </li>

                        <li>
                          <Link to="/fixedeparture">Fixed Departures </Link>
                        </li>

                        <li>
                          <Link to="/user-registration">User Register</Link>
                        </li>

                        <li>
                          <Link to="/agent-registration">Partner Register</Link>
                        </li>
                      </ul>
                    </Col>
                    <Col md={4} xs={24}>
                      <p>SYT INFO</p>
                      <ul className="cms-pages-links">
                        <li>
                          <Link to="/about-us">About Us </Link>
                        </li>

                        {/* <li>
                          <Link to="/ourteam">Our Team </Link>
                        </li>

                        <li>
                          <Link to="/testimonials">Testimonials </Link>
                        </li> */}

                        <li>
                          <Link to="/faqs">Faqs </Link>
                        </li>

                        <li>
                          <Link to="/termsofconditions">
                            Terms of Conditions{" "}
                          </Link>
                        </li>

                        <li>
                          <Link to="/privacypolicy">Privacy Policy </Link>
                        </li>

                        <li>
                          <Link to="/disclaimer">Disclaimer </Link>
                        </li>

                        <li>
                          <Link to="/careers">Careers </Link>
                        </li>

                        {/* <li>
                          <Link to="/Socialresponsibility">
                            Social Responsibility{" "}
                          </Link>
                        </li> */}

                        <li>
                          <Link to="/web-check-in">Check Flight Info </Link>
                        </li>

                        {/* <li>
                          <Link to="/Refundpolicy">Refund Policy </Link>
                        </li>

                        <li>
                          <Link to="/Investorrelations">
                            Investor Relations{" "}
                          </Link>
                        </li> */}

                        <li>
                          <Link to="/flightenquiry">Flight Enquiry</Link>
                        </li>
                        <li>
                          <Link to="/contact_us_form">Contact Us</Link>
                        </li>
                        <li>
                          <Link to="/bus-hire">Bus Hire</Link>
                        </li>
                        <li>
                          <Link to="/forex">Forex</Link>
                        </li>
                        <li>
                          <Link to="/car-hire">Car Hire</Link>
                        </li>
                      </ul>
                    </Col>

                    <Col md={4} xs={24}>
                      <p>SITE DIRECTORY</p>
                      <ul className="cms-pages-links">
                        <li>
                          <a href="/">Flight by City </a>
                        </li>

                        <li>
                          <Link to="/activities">Activities </Link>
                        </li>

                        <li>
                          <Link to="/blog">Blog </Link>
                        </li>

                        {/* <li>
                          <Link to="/">Sitemap </Link>
                        </li> */}

                        <li>
                          <Link to="/offers">Flight Offers </Link>
                        </li>
                      </ul>
                    </Col>

                    <Col md={4} xs={24}>
                      <p>POPULAR AIRLINES</p>
                      <ul className="cms-pages-links">
                        {/* <li>
                          <Link to="/#">IndiGo Airlines </Link>
                        </li> */}
                        <li>
                          <a href="https://www.goindigo.in/">IndiGo Airlines</a>
                        </li>


                        <li>
                          <a href="https://www.airindia.com/">Air India Airlines </a>
                        </li>

                        {/* <li>
                          <Link to="/#">GoAir Airlines </Link>
                        </li> */}

                        {/* <li>
                          <Link to="/#">Vistara Airlines </Link>
                        </li> */}

                        <li>
                          <a href="https://book.spicejet.com/">SpiceJet Airlines </a>
                        </li>

                        <li>
                          <a href="https://www.airasia.com/">Air Asia Airlines </a>
                        </li>

                        <li>
                          <a href="https://www.britishairways.com/">British airways </a>
                        </li>

                        <li>
                          <a href="https://www.qatarairways.com/">Qatar airways </a>
                        </li>

                        <li>
                          <a href="https://www.singaporeair.com/">Singapore Airlines </a>
                        </li>

                        <li>
                          <a href="https://www.etihad.com/">Etihad airways </a>
                        </li>

                        <li>
                          <a href="https://www.thaiairways.com/">Thai airways </a>
                        </li>

                        <li>
                          <a href="https://www.united.com/">United Airlines </a>
                        </li>

                        <li>
                          <a href="https://www.americanairlines.in/">American Airlines </a>
                        </li>

                        <li>
                          <a href="https://www.malaysiaairlines.com/">Malaysia Airlines </a>
                        </li>
                      </ul>
                    </Col>

                    <Col md={8} xs={24} className="footer-flex">
                      <Row>
                        <Col span={12}>
                          <p>PAYMENT & SECURITY</p>
                          <div className="pay-ment-img">
                            <ul className="footer-visa-images">
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-1.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-2.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-3.jpg")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                              <li>
                                {" "}
                                <img
                                  className=""
                                  src={
                                    require("../../assets/images/visa-footer-4.png")
                                      .default
                                  }
                                  alt=""
                                />
                              </li>
                            </ul>

                            <p>FOLLOW US ON</p>
                            <div className="social_wrapper">
                              <div className="social_icons">
                                <a href={siteDetails.facebook} target="_blank">
                                  <FacebookOutlined />
                                </a>
                                <a href={siteDetails.youtube} target="_blank">
                                  <YoutubeOutlined />
                                </a>
                                <a href={siteDetails.twitter} target="_blank">
                                  <TwitterOutlined />
                                </a>
                                <a href={siteDetails.instagram} target="_blank">
                                  <InstagramOutlined />
                                </a>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {
                        siteDetails?.BusinessAddress?.length > 0 && (
                          <>
                            {siteDetails?.BusinessAddress.map(x => {
                              return (<div className="footer-address-cc">
                                <ul>
                                  <li>
                                    <i className="fa fa-map-marker"></i>
                                    <p>
                                      Address:{" "}
                                      {x?.Address},
                                      <br />
                                      {x?.CityName},
                                      {x?.CountryName},{" "}
                                      {x?.PostalCode}
                                    </p>
                                  </li>
                                  <li>
                                    <i className="fa fa-phone"></i>
                                    <p>Phone: {x.PhoneNumber} </p>
                                  </li>
                                </ul>
                                <ul>
                                  <li>
                                    <i className="fa fa-envelope-o"></i>
                                    <p>Email: {x.Email}</p>
                                  </li>
                                </ul>
                              </div>)
                            })}
                            <ul>
                              <li>
                                <i className="fa fa-map-marker" style={{color:'#ea1b37'}}></i>
                                <span className="FTLocation">
                                      More Locations:{" "}AHMEDABAD, THAILAND, AUSTRALIA, EUROPE, USA 
                                </span>
                              </li>
                            </ul>
                          </>
                        )}
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <div className="copyright">
              Copyright &copy;2021 {AppConstants.DOMAIN_NAME}. All rights
              reserved.
            </div>
            {/* <div className="social_wrapper">
              <div className="social_icons">
                <a href={siteDetails.facebook} >
                  <FacebookOutlined />
                </a>
                <a href={siteDetails.youtube} >
                  <YoutubeOutlined />
                </a>
                <a href={siteDetails.twitter} >
                  <TwitterOutlined />
                </a>
                <a href={siteDetails.instagram} >
                  <InstagramOutlined />
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
